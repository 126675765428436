import $ from 'jquery';

export function switchActive(url, id) {
    $('#' + id).change(function () {
        const active = this.checked;
        const alertId = $(this).val();
        const data = {
            'active': active,
            'alert_id': alertId
        };
        $.post(url, data)
            .done(function (data) {
                console.log(data.success)
            })
            .fail(function () {
                console.error('Failed to update alert')
            });
    });
}

export function displaySummarySettings() {
    const summaryRadio = document.getElementById('frequency-scheduled');
    const radioButtons = document.querySelectorAll('input[name="frequency"]');
    const toggleSections = document.getElementsByClassName('settings-for-summary');
    handleSummaryChanges(summaryRadio, toggleSections, radioButtons);
}

export function displayNewslettersSummarySettings() {
    ['general', 'routes'].forEach(function (newsletterType){
        const summaryRadio = document.getElementById(newsletterType + '_news_frequency-scheduled');
        const radioButtons = document.querySelectorAll('.content-' + newsletterType + '-newsletters input[name="' + newsletterType + '_news_frequency"]');
        const toggleSections = document.querySelectorAll('.content-' + newsletterType + '-newsletters .settings-for-summary');
        handleSummaryChanges(summaryRadio, toggleSections, radioButtons);
    });
}

function handleSummaryChanges(summaryRadio, toggleSections, radioButtons) {
    for (let i = 0, all = toggleSections.length; i < all; i++) {
        if (summaryRadio.checked) {
            toggleSections[i].classList.add('show');
        }
    }

    function handleRadioClick() {
        for (let i = 0, all = toggleSections.length; i < all; i++) {
            if (summaryRadio.checked) {
                toggleSections[i].classList.add('show');
            } else {
                toggleSections[i].classList.remove('show');
            }
        }
    }

    radioButtons.forEach(radio => {
        radio.addEventListener('click', handleRadioClick);
    });
}