import {decodeHash} from "../../../common/hash/util";

export function showSubmenu(header) {
    const location = window.location;
    const menuSelector = '.header__menu > ul.menu__searches';

    const splitLink = link => link.split('#')[0];
    const getSlug = link => link.pathname.split('/').slice(1)[0];

    const url = splitLink(location.href);
    const isNewsSearch = getSlug(location) === 'news';

    // Add classes to submenu links
    const submenuLinks = header.querySelectorAll(`${menuSelector} div.search-submenu a`);
    const isNewsLink = (link) => getSlug(link) === 'news';
    let isFirstLink = true;

    submenuLinks.forEach(link => {
        if (isNewsSearch && isNewsLink(link)) {
            highlightNewsSubMenuLink(link);
        } else if (splitLink(link.href) === url) {
            if (isFirstLink) {
                highlightSubMenuLink(link);
                isFirstLink = false;
            }
        }
    });

    if (isNewsSearch) {
        window.addEventListener("hashchange", () => {
            submenuLinks.forEach(link => {
                if (isNewsLink(link)) {
                    highlightNewsSubMenuLink(link);
                }
            });
        });
    }

    // Disable href on touch devices for menu items with submenu
    const links = header.querySelectorAll(`${menuSelector} > li > a:not(.active)`);
    links.forEach(link => {
        if (link.nextSibling && 'ontouchstart' in window) {
            link.addEventListener('click', e => {
                e.preventDefault();

                links.forEach(link => {
                    link.classList.remove('active')
                })

                link.classList.add('active');
            });
        }

        if (splitLink(link.href) === url) {
            link.classList.add('active');
        }
    })
}

const getHash = link => link.hash.substring(1);

function openSubmenu(submenu) {
    const header = document.getElementById("header");
    header.classList.add('searches-submenu-opened');
    submenu.classList.add('show');
    submenu.previousElementSibling.classList.add('active');
}

function highlightSubMenuLink(link) {
    const submenu = link.parentElement.parentElement.parentElement;
    openSubmenu(submenu);
    link.classList.add('active');
}

function highlightNewsSubMenuLink(link) {
    const newsTypeFromHash = decodeHash(getHash(location))?.input;
    const generalNewsFilter = document.getElementById('news_type_typeGeneral');
    const criterion = generalNewsFilter ? generalNewsFilter.parentElement.parentElement : null;
    const newsTypesFromFilters = criterion ? Array.from(criterion.querySelectorAll("input[type=checkbox]:checked"), e => e.value) : [];

    const getNewsTypeInput = () => {
        if (newsTypeFromHash) {
            return newsTypeFromHash.news_type[0];
        } else if (newsTypesFromFilters.length === 1) {
            return newsTypesFromFilters[0];
        }

        return null;
    }

    const newsTypeInput = getNewsTypeInput();

    const submenuLinkNewsType = decodeHash(getHash(link))?.input.news_type[0];
    link.classList.remove('active');

    if (newsTypeInput && newsTypeInput === submenuLinkNewsType) {
        highlightSubMenuLink(link);
    } else if (newsTypesFromFilters.length > 1) {
        openSubmenu(link.parentElement.parentElement.parentElement);
    }
}