import { waitForElement } from "../common/waitForElement";

export function toggleRows() {
	waitForElement(".content-box").then(() => {
		const buttons = document.querySelectorAll(".btn-toggle-rows");

		if (buttons.length > 0) {
			buttons.forEach((button) => {
				button.addEventListener("click", function () {
					const tableId = button.getAttribute("aria-controls");
					const table = document.getElementById(tableId);
					const expanded =
						button.getAttribute("aria-expanded") === "true";
					button.blur();
					table.classList.toggle("expanded");
					const allHiddenRows = Array.from(
						table.children[1].children,
					);

					if (expanded) {
						button.setAttribute("aria-expanded", "false");
						button.innerText = "Show complete list";
						allHiddenRows.forEach((el, idx) => {
							if (idx > 9) {
								el.style.display = "none";
							}
						});
					} else {
						button.setAttribute("aria-expanded", "true");
						button.innerText = "Show Top 10";
						allHiddenRows.forEach((el) => {
							if (el.style.display === "none") {
								el.style.display = "table-row";
							}
						});
					}
				});
			});
		}
	});
}
