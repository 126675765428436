export function profilePage() {
    const printButton = document.getElementById('printPage');

    if (printButton) {
        printButton.addEventListener('click', () => {
            const charts = document.querySelectorAll('.chart-wrapper');
            const chartsAreRestricted = document.querySelector('.tab--restricted .charts-container');
            let printViewOpened = false;

            const allChartImagesAreLoaded = () => {
                const chartImages = document.querySelectorAll('.chart-image');
                return Array.from(chartImages).every((image) => image.classList.contains('chart-image-loaded'));
            };

            const handlePrint = (timeout) => {
                if (!printViewOpened) {
                    setTimeout(function (){
                        removeLoader();
                        window.print();
                    }, timeout);
                    printViewOpened = true;
                }
            };

            if (!chartsAreRestricted && charts.length !== 0 && !allChartImagesAreLoaded()) {
                initLoader();

                charts.forEach((chart) => {
                    const chartImage = chart.parentNode.querySelector('.chart-image');

                    const observer = new MutationObserver(() => {
                        if (allChartImagesAreLoaded()) {
                            handlePrint(1000);
                        }
                    });

                    observer.observe(chartImage, {attributeFilter: ['class']});
                })
            } else {
                handlePrint();
            }
        });
    }

    function initLoader() {
        printButton.classList.add('loading');
    }

    function removeLoader() {
        printButton.classList.remove('loading');
    }
}