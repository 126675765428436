import { addOverlay, removeOverlay } from "./utils";
import { waitForElement } from "./waitForElement";

export function initRestrictedPopovers() {
	waitForElement(".content-box").then(() => {
		const toggles = document.querySelectorAll(
			".restricted-content .content-toggle",
		);
		const closeButtons = document.querySelectorAll(
			'.restricted-content .content-wrapper [data-dismiss="modal"]',
		);
		let popoversAreInitialized = false;

		if (window.innerWidth < 768) {
			initPopovers();
		}

		window.addEventListener("resize", () => {
			if (window.innerWidth < 768) {
				if (!popoversAreInitialized) {
					initPopovers();
				}
			} else {
				const toggle = document.querySelector(
					'.restricted-content .content-toggle[aria-expanded="true"]',
				);
				hide(toggle);
				toggle?.blur();
			}
		});

		function initPopovers() {
			toggles.forEach((toggle) => {
				toggle.addEventListener("click", (e) => {
					if (window.innerWidth < 768) {
						e.preventDefault();
						toggle?.attributes["aria-expanded"].value === "true"
							? hide(toggle)
							: show(toggle);
						toggle?.blur();
					}
				});
			});

			closeButtons.forEach((button) => {
				button.addEventListener("click", (e) => {
					e.preventDefault();
					const toggle = button
						.closest(".restricted-content")
						.querySelector('.content-toggle[aria-expanded="true"]');
					hide(toggle);
				});
			});

			document.addEventListener("click", (e) => {
				const toggle = document.querySelector(
					'.restricted-content .content-toggle[aria-expanded="true"]',
				);
				if (
					toggle &&
					!e.target.closest(
						'.content-toggle[aria-expanded="true"]',
					) &&
					!e.target.closest(".content-wrapper.show")
				) {
					hide(toggle);
				}
			});

			popoversAreInitialized = true;
		}

		function hide(toggle) {
			if (!toggle) {
				return;
			}

			const popover =
				toggle.parentElement.querySelector(".content-wrapper");
			toggle.setAttribute("aria-expanded", "false");
			popover.classList.remove("show");
			toggleBackdrop();
			removeOverlay();
		}

		function show(toggle) {
			const popover =
				toggle.parentElement.querySelector(".content-wrapper");
			toggle.setAttribute("aria-expanded", "true");
			popover.classList.add("show");
			toggleBackdrop();
			addOverlay();
		}

		function toggleBackdrop() {
			document.documentElement.classList.toggle(
				"backdrop--fixed--opened",
			);
		}
	});
}
