import jQuery from 'jquery';

export function initSinglePage(pageId, title) {
    const $url = jQuery(`#${pageId}`);
    const $contentContainer = jQuery(`#page-content`);
    const url = $url.data('url');

    function updateContent(contentUrl) {
        $contentContainer.addClass('loading');
        $contentContainer.html('<span class="loader">Loading...</span>');
        jQuery.get(contentUrl)
            .done(function (data) {
                $contentContainer.removeClass('loading');
                $contentContainer.html(data);
            })
            .fail(function () {
                $contentContainer.html('Failed to load page.');
                $contentContainer.removeClass('loading');
            });
    }

    function onHashChange() {
        updateContent(url, title);
    }

    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
        onHashChange();
    })

    onHashChange();
}