import $ from 'jquery';

export function addCountryTag(modalId, countryId, countryName, continentSlug) {
    // Due to some event listener weirdness, this gets called when the
    // modal is closed by clicking "cancel" or "X", but not when clicking
    // outside the modal. So we'll check if the given country is already
    // shown as a tag, so that we don't add it multiple times.
    if ($(`${modalId} .tag-country[data-country-id="${countryId}"]`).length) {
        return;
    }

    if (countryName) {
        const continentList = $(`${modalId} ul.selected-countries-tags.continent-${continentSlug}`);
        addCountryToList(continentList, countryId, countryName);
        continentList.removeClass('hide');

        const allContinentsList = $(`${modalId} ul.selected-countries-tags.continent-all`);
        addCountryToList(allContinentsList, countryId, countryName);
        allContinentsList.removeClass('hide');
    }
}

function addCountryToList(list, countryId, countryName) {
    const collator = new Intl.Collator('en');
    const countryTag = (hide) => createCountryTag(countryId, countryName, hide);
    const countries = list.find('.tag-country');
    let added = false;
    let hide = false;

    if (countries.length === 1) {
        list.append(countryTag(hide));
        return;
    }

    countries.each(function (idx, el) {
        if (added) {
            return;
        }

        // Hide tags under (+) sign
        if (idx >= 4 && !isListExpanded(list)) {
            hide = true;

            addMoreButton(list);
        }

        // Sorts in new country if needed
        if (collator.compare(countryName, el.textContent.trim()) === -1) {
            $(el).before(countryTag(hide));
            added = true;

            // Always show first 5 elements only
            const countriesShown = countries.filter(function () {
                return !this.classList.contains('hide');
            });

            if (countriesShown.length === 5) {
                countries[4].classList.add('hide'); // Hide 5th country
                addMoreButton(list);
            }
        }
    });

    if (!added) {
        isListExpanded(list) ? countryTag(hide).insertBefore(countries.last()) : list.append(countryTag(hide));
        hide = false;
    }
}

function createCountryTag(countryId, countryName, hide) {
    const removeCountryButton = $(`<button type="button" class="tag__remove" data-country-id="${countryId}">`);
    const hideClass = hide ? "hide" : "";
    removeCountryButton.on('click', onRemoveCountryButtonClick);

    return $(`<li class="tag tag-grey tag-removable tag-country ${hideClass}" data-country-id="${countryId}">`)
        .append(
            `<span>${countryName}</span>`,
            removeCountryButton
        );
}

export function removeCountryTag(modalId, countryId) {
    const tagLists = $(`${modalId} ul.selected-countries-tags`).filter(function () {
        return $(this).children().length > 1;
    });
    $(`${modalId} li.tag-country[data-country-id="${countryId}"]`).remove();

    tagLists.each(function () {
        const ul = $(this);
        const countries = ul.find('li.tag-country');

        if (countries.length === 5) {
            removeMoreButton(countries);
        }

        if (countries.length === 0) {
            ul.addClass('hide');
        }

        countries.each(function (index) {
            if (index < 5) {
                this.classList.remove('hide')
            }
        })

        moveLoadMoreToEnd(ul);
    });
}

export function onRemoveCountryButtonClick(event) {
    // Propagation needs to be stopped, otherwise it's
    // caught in modals.js:35 and the modal is closed.
    event.stopPropagation();

    const modalId = '#' + this.closest('form.regions__form.modal__body').id;
    $(modalId + '-' + this.dataset.countryId).prop('checked', false);
    $(modalId + '-' + this.dataset.countryId + '-all').prop('checked', false);
    removeCountryTag(modalId, this.dataset.countryId);
}

function addMoreButton(list) {
    if (list.find('.choices-toggle').length === 0) {
        const moreButton = $(`<li class="tag tag-transparent choices-toggle choices-toggle--expand"><button type="button">Load more</button></li>`);
        moreButton.on('click', onLoadButtonClick);
        list.append(moreButton);
    }
}

function removeMoreButton(list) {
    const button = list.parent().find('.choices-toggle:first');

    if (button) {
        button.remove();
    }
}

function onLoadButtonClick(e) {
    // Propagation needs to be stopped, otherwise it's
    // caught in modals.js:35 and the modal is closed.
    e.stopPropagation();

    const tag = $(this);
    const button = tag.children()[0];
    const list = tag.closest('.selected-countries-tags');
    const toggleClass = 'choices-toggle--expand';
    const collapsed = !isListExpanded(list);

    moveLoadMoreToEnd(list);

    if (collapsed) {
        list.find('li.tag-country').each(function () {
            $(this).removeClass('hide');
        });

        button.textContent = 'Load less';
        tag.removeClass(toggleClass);
    } else {
        list.find('li.tag-country').each(function (index) {
            if (index >= 5) {
                $(this).addClass('hide');
            }
        });

        button.textContent = 'Load more';
        tag.addClass(toggleClass);
    }
}

function isListExpanded(list) {
    return list.find('.choices-toggle:not(.choices-toggle--expand)').length > 0;
}

function moveLoadMoreToEnd(list) {
    const button = list.find('.choices-toggle:first');

    if (button && list.children().last().hasClass('tag-country')) {
        list.append(button);
    }
}