import $ from "jquery";
import { sanitizeHTML } from "../search/utils";

export function initCapacitiesSelection() {
	const url = this.dataset.value;
	const contentContainer = document.getElementById("tabs-content");

	$.get(url)
		.done(function (data) {
			contentContainer.innerHTML = sanitizeHTML(data);
			window.initDropdowns(
				"#weeklyCapacity",
				window.initCapacitiesSelection,
			);
			// Fix tab links
			const tabGroups = $(".tabs.content-box");
			tabGroups.each(function (index, element) {
				const tabGroup = $(element);
				const tabLinkLists = tabGroup.find(".tabs-list li");
				const tabLinksContent = tabGroup.find(".tab-pane");
				const tabLinks = tabGroup.find(".tabs-list li a");
				tabLinks.each(function (index, element) {
					const tabId = index + 1;
					$(element).click(function (event) {
						event.preventDefault();
						tabLinks.each(function () {});
						if (!$(this).parent().hasClass("is-active")) {
							tabLinkLists.each(function (index, element) {
								$(element).removeClass("is-active");
							});
							$(this).parent().addClass("is-active");
							tabLinksContent.each(function (index, element) {
								$(element).removeClass("is-active");
							});
						}
						tabGroup.find("#tab-" + tabId).addClass("is-active");
					});
				});
			});
		})
		.fail(function () {
			contentContainer.innerHTML = "Failed to load page";
		});
}
