import {getCookie, setCookie} from "./cookies";

export function toggleDesktopView() {
    const switchToDesktopButton = document.getElementById('btnSwitchToDesktop');

    if (switchToDesktopButton) {
        const viewport = document.getElementsByTagName('meta')['viewport'];
        const viewModeCookie = "view-mode";

        const setMobileView = () => {
            viewport.setAttribute('content', 'width=device-width, initial-scale=1');
            switchToDesktopButton.textContent = 'Switch to desktop version';
            switchToDesktopButton.setAttribute('style', '');
        };

        const setDesktopView = () => {
            viewport.setAttribute('content', 'width=1440');
            switchToDesktopButton.textContent = 'Switch to mobile version';
            switchToDesktopButton.parentElement.setAttribute('style', 'display: block;');
        };

        switchToDesktopButton.addEventListener('click', () => {
            const viewMode = getCookie(viewModeCookie);

            if (viewMode === "desktop") {
                setCookie(viewModeCookie, 'mobile', 30);
                setMobileView();
            } else {
                setCookie(viewModeCookie, 'desktop', 30);
                setDesktopView();
            }
        });

        getCookie(viewModeCookie) === "desktop" ? setDesktopView() : setMobileView();
    }
}