import {api} from "../../common/favorites/api";
import {closeModal} from "../../common/modals";
import {displayToasts, errorToast, successToast} from "../../common/toasts/displayToasts";
import {displayList, onOpenEditModal, removeListFromDisplay} from "./utils";

export function editList(endpoint, modalSelectorEdit, modalSelectorDelete, isListPage) {
    const modalEdit = document.getElementById(modalSelectorEdit);
    const editModalButtons = document.querySelectorAll('[data-modal="modalEdit"]');
    const editButton = modalEdit.querySelector('#btnEdit');

    const inputNewName = modalEdit.querySelector('input[name="name"]');

    editModalButtons.forEach(button => {
        button.addEventListener('click', () => onOpenEditModal(button, modalSelectorEdit));
    });

    inputNewName.addEventListener('input', () => {
        inputNewName.parentNode.classList.contains('error') ? inputNewName.parentNode.classList.remove('error') : null;
    });

    editButton.addEventListener('click', () => {
        const id = modalEdit.dataset.id;
        const newName = inputNewName.value.trim();
        const currentName = document.getElementById('listName' + id);

        if (newName.length === 0) {
            inputNewName.parentNode.classList.add('error');
            return;
        }

        if (newName === currentName.innerText.trim()) {
            displayToasts([errorToast('List already has that name.')]);
            return;
        }

        editButton.textContent = 'Saving...';

        api(endpoint).save(id, newName,
            (data) => {
                displayToasts([successToast('List name has been changed.')]);

                if (isListPage) {
                    currentName.innerText = newName;
                } else {
                    removeListFromDisplay(id);
                    displayList(data[0], modalSelectorEdit, modalSelectorDelete);
                }

                closeModal(modalSelectorEdit);
                editButton.textContent = 'Save';
            },
            () => {
                displayToasts([errorToast('Failed to change list name.')]);
                editButton.textContent = 'Save';
            }
        );
    });
}