import {api} from "../../common/favorites/api";
import {closeModal} from "../../common/modals";
import {displayToasts, errorToast, successToast} from "../../common/toasts/displayToasts";

export function initDeleteFavoriteListItem(modalSelector) {
    const deleteButtons = document.querySelectorAll('.btnDeleteItem');
    const modal = document.getElementById(modalSelector);
    const modalContent = modal.querySelector('.modal__content p')
    const buttonDelete = modal.querySelector('#btnDeleteItem');

    let id, favoriteItemElement;

    deleteButtons.forEach(button => {
        button.addEventListener('click', () => {
            id = button.dataset.id;
            favoriteItemElement = button.closest('.favorite-item');
            let airlineName = favoriteItemElement.dataset.airlineName;

            if (!airlineName) {
                airlineName = 'the airline';
            }

            modalContent.textContent = modalContent.dataset.contentTemplate.replace('__AIRLINE-NAME__', airlineName);
        });
    })

    buttonDelete.addEventListener('click', function () {
        const code = favoriteItemElement.dataset.code;
        const data = JSON.stringify({
            codes: [code],
            lists: [id],
        });

        buttonDelete.textContent = 'Removing...';

        api('/api/v1/me/favorites').delete(data,
            () => {
                displayToasts([successToast('Airline has been removed from the list.')]);
                favoriteItemElement.remove();
                closeModal(modalSelector);
                buttonDelete.textContent = 'Yes, remove';
            },
            () => {
                displayToasts([errorToast('Failed to remove airline from the list.')]);
                buttonDelete.textContent = 'Yes, remove';
            }
        );
    });
}
