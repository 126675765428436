import $ from "jquery";
import { getMessagesMargin } from "./common/toasts/messagesMargin";
import { showElement, waitForCookiesBanner } from "./common/utils";

export function notification() {
	document.addEventListener(
		"DOMContentLoaded",
		() => {
			const notification = document.getElementById("notification");
			const banner = document.getElementById("industry-and-job-modal");

			if (notification) {
				const notificationLink =
					notification.querySelector(".notification-link");

				$.ajax({
					url: "/notifications/current",
					method: "GET",
					success: (item) => {
						if (typeof item != "undefined") {
							notification.dataset.id = item.id;
							notification.querySelector(
								".notification-title",
							).innerText = item.title;
							notification.querySelector(
								".notification-body p",
							).innerText = item.body;

							if (banner) {
								const margin = getMessagesMargin();
								notification.style.bottom =
									banner.offsetHeight + margin * 2 + "px";
							}

							waitForCookiesBanner(
								(...args) => onReady(notification, ...args),
								onClose,
							);
							showElement(notification);

							if (
								item.button_text !== null &&
								item.button_url !== null
							) {
								notificationLink.setAttribute(
									"href",
									item.button_url,
								);
								notificationLink.innerText = item.button_text;
								notification.querySelector(
									".notification-footer",
								).style.display = "block";
							}
						}
					},
				});

				notification
					.querySelector(".btn--close")
					.addEventListener("click", () => {
						$.ajax({
							url:
								"/notifications/" +
								notification.dataset.id +
								"/closed",
							method: "POST",
							success: () => {
								notification.remove();
							},
						});
					});

				function onReady(notification, height) {
					const margin = getMessagesMargin();
					const bannerOffset = banner
						? banner.offsetHeight + margin
						: 0;

					if (window.innerWidth < 768) {
						notification.style.bottom =
							height + bannerOffset + margin + "px";
					} else {
						notification.style.bottom =
							height + bannerOffset + margin * 2 + "px";
					}
				}

				function onClose() {
					notification.style.bottom = "";
				}
			}
		},
		false,
	);
}
