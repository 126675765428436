import $ from "jquery";

export function displayForecastingDisclaimer() {
	$(".tabs-nav li").each(function () {
		$(this).click(function () {
			if ($(this).first().text().trim() === "Forecasted Data") {
				$("#forecasting-disclaimer").show();
			} else {
				$("#forecasting-disclaimer").hide();
			}
		});
	});
}
