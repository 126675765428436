import $ from "jquery";

export function renderAllTabsOnPrint() {
    window.onbeforeprint = () => {
        $('.tab-pane:not(.tab--restricted)').each(function () {
            const id = $(this).attr('id');
            const label = $(this).parent().prev().find(`a[href$=${id}]`).text();
            $(this).before(`<h5 class="tab-pane-print-label">${label}</h5>`);
        });
        $('#printPage').blur();
    };

    window.onafterprint = () => {
        $('.tab-pane-print-label').remove();
    };
}