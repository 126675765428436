export function forms() {
    const showPasswordButtons = document.querySelectorAll('[data-show="password"]');

    function showPassword() {
        const passwordInput = this.parentNode.getElementsByTagName('input')[0];

        if (passwordInput.type === "password") {
            passwordInput.type = "text";
        } else {
            passwordInput.type = "password";
        }
    }

    if (showPasswordButtons) {
        showPasswordButtons.forEach(function (button) {
            button.addEventListener('click', showPassword);
        });
    }
}