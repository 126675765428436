import { sanitizeHTML } from "../../../search/utils";

export function removeListFromDisplay(id) {
	document.getElementById("listItem" + id).remove();
}

export function redirectToAllListsPage() {
	window.location.href = "/account-management/lists";
}

export function onOpenEditModal(button, modalSelector) {
	const id = button.dataset.id;
	const name = document.getElementById("listName" + id);
	const modal = document.getElementById(modalSelector);
	const input = modal.querySelector('input[name="name"]');
	modal.dataset.id = id;
	input.value = name.innerText;
	input.focus();
}

export function initDeleteModal(button, modalSelector) {
	button.addEventListener("click", () => {
		const modal = document.getElementById(modalSelector);
		modal.dataset.id = button.dataset.id;
	});
}

export function displayList(list, editModalSelector, deleteModalSelector) {
	const lists = document.querySelectorAll(".favorite-list");

	const newList = createNewList(list, editModalSelector, deleteModalSelector);

	if (lists.length > 0) {
		let divElement = null;

		lists.forEach((div) => {
			const name = div.querySelector("h2").innerText;

			if (list.name < name) {
				return;
			}

			divElement = div;
		});

		if (divElement === null) {
			divElement = lists[0];
			divElement.insertAdjacentHTML("beforebegin", sanitizeHTML(newList));
		} else {
			divElement.insertAdjacentHTML("afterend", sanitizeHTML(newList));
		}
	} else {
		const listsWrapper = document.querySelector(".favorite-lists");
		listsWrapper.innerHTML = newList;
	}

	const listItem = document.getElementById("listItem" + list.id);
	const buttonEdit = listItem.querySelector(".btnEdit");
	buttonEdit.addEventListener("click", () =>
		onOpenEditModal(list.id, editModalSelector),
	);

	const buttonDelete = listItem.querySelector(".btnDelete");
	initDeleteModal(buttonDelete, deleteModalSelector);

	window.initModals();

	function createNewList(list, editModalSelector, deleteModalSelector) {
		const newList = document.createElement("div");
		newList.classList.add("favorite-list");
		newList.setAttribute("id", `listItem${list.id}`);

		const title = document.createElement("h2");
		title.classList.add("title");
		title.setAttribute("id", `listName${list.id}`);
		title.innerText = list.name;

		const actions = document.createElement("div");
		actions.classList.add("actions", "flex", "align-top");
		actions.setAttribute("data-id", list.id);

		const btnView = document.createElement("a");
		btnView.classList.add("btn", "btn--secondary");
		btnView.href = "/account-management/lists/";
		btnView.innerText = "View";

		const btnEdit = document.createElement("a");
		btnEdit.classList.add(
			"btn",
			"btn--secondary",
			"btn--icon",
			"btn--edit",
			"btnEdit",
		);
		btnEdit.setAttribute("data-modal", editModalSelector);
		btnEdit.setAttribute("data-id", list.id);

		const btnDelete = document.createElement("a");
		btnDelete.classList.add(
			"btn",
			"btn--secondary",
			"btn--icon",
			"btn--delete",
			"btnDelete",
		);
		btnDelete.setAttribute("data-modal", deleteModalSelector);
		btnDelete.setAttribute("data-id", list.id);

		actions.append(btnView, btnEdit, btnDelete);
		newList.appendChild(title);
		newList.appendChild(actions);

		return newList;
	}
}

export function showEmptyPlaceholder() {
	const favoriteLists = document.querySelector(".favorite-lists");
	let box = document.createElement("section");
	box.classList.add("content-box");

	const div =
		'<div class="content-empty grid direction-column align-center justify-center text-center">\n' +
		'        <img src="/static/img/icons/icon-no-search-results.svg" alt="">\n' +
		"        <h5>Empty</h5>\n" +
		"        <p>No user defined lists created yet.</p>\n" +
		"    </div>";

	box.insertAdjacentHTML("beforeend", sanitizeHTML(div));
	favoriteLists.append(box);
}
