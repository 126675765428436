import {createList} from "./createList";
import {deleteList} from "./deleteList";
import {editList} from "./editList";

export function initManageFavoriteLists(modalSelectorEdit, modalSelectorDelete, modalSelectorCreate = null) {
    const endpoint = '/api/v1/me/favoriteLists';
    const isIndividualListPage = modalSelectorCreate === null;

    editList(endpoint, modalSelectorEdit, modalSelectorDelete, isIndividualListPage);
    createList(endpoint, modalSelectorEdit, modalSelectorDelete, modalSelectorCreate, isIndividualListPage);
    deleteList(endpoint, modalSelectorDelete, isIndividualListPage);
}