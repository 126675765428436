import {onContentLoad} from "./onContentLoad";

export function tabs() {
    onContentLoad(function () {
        const tabsNavSelector = '.tabs-nav .tabs-list li';
        const tabNavItems = document.querySelectorAll(tabsNavSelector);

        if (tabNavItems) {
            tabNavItems.forEach(tab => {
                tab.addEventListener('click', (e) => {
                    e.preventDefault();
                    highlightActiveTab(tab);
                })
            });
        }
    });
}

export function highlightActiveTab(tab) {
    const activeClass = 'is-active';
    const tabsContainer = tab.closest('.tabs');
    const tabsNavSelector = '.tabs-nav .tabs-list li';
    const tabsContentSelector = '.tabs-content .tab-pane';

    removeActiveTab(tabsContainer);
    addActiveTab(tab, tabsContainer);

    function removeActiveTab(tabsContainer) {
        tabsContainer.querySelectorAll(tabsNavSelector).forEach(tab => {
            tab.classList.remove(activeClass);
            tab.setAttribute('aria-selected', 'false');
        });
        tabsContainer.querySelectorAll(tabsContentSelector).forEach(section => {
            section.classList.remove(activeClass);
            section.setAttribute('tabindex', '-1');
        });
    }

    function addActiveTab(tab, tabsContainer) {
        const href = tab.querySelector('a').getAttribute('href');
        const matchingSection = tabsContainer.querySelector(href);

        tab.classList.add(activeClass);
        tab.setAttribute('aria-selected', 'true');
        matchingSection.classList.add(activeClass);
        matchingSection.setAttribute('tabindex', '0');
    }
}