import {
	addBackdrop,
	addBackdropOverlay,
	addOverlay,
	removeBackdrop,
	removeBackdropOverlay,
	removeOverlay,
} from "../utils";

const menuOpened = "opened";
const headerSubmenuOpened = "submenu--opened";

export function toggleMenu(header, breakpoint) {
	const menuButtons = header.querySelectorAll("[data-submenu]");

	menuButtons.forEach((button) => {
		button.addEventListener("click", (e) => {
			const menu = button.parentElement;
			const submenuId = button.dataset.submenu;
			const menuIsOpened = menu.classList.contains(menuOpened);
			const openBackdrop = button.dataset.backdrop;
			const focusElement = menu.querySelector("[data-autofocus]");

			if (window.innerWidth < breakpoint || "ontouchstart" in window) {
				e.preventDefault();
			}

			if (menuIsOpened) {
				closeBurgerMenu(menu);
			} else {
				header.querySelectorAll(".opened").forEach((menu) => {
					closeBurgerMenu(menu);
				});

				if (openBackdrop === "true") {
					addBackdrop();
				} else if (openBackdrop === "overlay") {
					addBackdropOverlay();
				}

				if (submenuId !== "headerGlobalSearchMenu") {
					addOverlay();
				}

				if (
					!(
						window.innerWidth >= breakpoint &&
						button.dataset.submenu === "headerAccountSubmenu"
					)
				) {
					header.classList.add(headerSubmenuOpened);
				}

				menu.classList.add(menuOpened);
			}

			if (focusElement) {
				focusElement.focus({ focusVisible: true });
			}

			button.blur();
		});
	});

	addEventListener("hashchange", () => {
		menuButtons.forEach((button) => {
			const menu = document.getElementById(
				button.dataset.submenu,
			).parentElement;
			const menuIsOpened = menu.classList.contains(menuOpened);

			if (menuIsOpened) {
				closeBurgerMenu(menu);
			}
		});
	});
}

export const closeBurgerMenu = (menu) => {
	const header = document.getElementById("header");
	menu.classList.remove(menuOpened);
	header.classList.remove(headerSubmenuOpened);
	removeOverlay();
	removeBackdrop();
	removeBackdropOverlay();
};
