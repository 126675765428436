import { closeModal } from "../modals";
import {
	displayToasts,
	errorToast,
	successToast,
} from "../toasts/displayToasts";
import { api } from "./api";
import {
	displayNewList,
	getListIds,
	getListsToDeleteFrom,
	getNewListName,
	getPreselectedLists,
	sortLists,
	uncheckFavorites,
	updateAddedListsState,
	updateAddToFavoritesButton,
	updateDeletedListsState,
	updateSaveButton,
} from "./utils";
import { validateForm, validateOnClick } from "./validation";

export function initFavorites(modalSelector, endpoint, entityCode) {
	const modal = document.getElementById(modalSelector);
	const saveButton = modal.querySelector("#btnSaveToFavoriteList");
	const newListCheckbox = modal.querySelector("#favoriteListsNew");
	const newListInputField = modal.querySelector("#newListName");

	validateForm(modal, newListInputField, newListCheckbox);

	saveButton.addEventListener("click", function () {
		const checkedLists = modal.querySelectorAll(
			'input[name="favoriteLists"]:checked',
		);

		let { listsChecked, listsToAddTo } = getListIds(checkedLists);
		let listsPreselected = getPreselectedLists(modal);
		let listsToDeleteFrom = getListsToDeleteFrom(
			listsChecked,
			listsPreselected,
		);
		let newListName = getNewListName(newListCheckbox, newListInputField);

		const valid = validateOnClick(
			modal,
			newListCheckbox,
			newListName,
			newListInputField,
			listsToAddTo,
			listsToDeleteFrom,
		);

		if (!valid) {
			return;
		}

		updateSaveButton(saveButton, "Saving...");

		if (listsChecked.length > 0 || newListName !== null) {
			let flashMessages = [];

			api(endpoint).create(
				newListName,
				[entityCode],
				listsChecked,
				(data) => {
					if (listsToAddTo.length > 0) {
						updateAddedListsState(modal, listsToAddTo);
						flashMessages.push(
							successToast(
								"Successfully added to " +
									listsToAddTo.length +
									" list(s).",
							),
						);
					}
					if (newListName !== null) {
						displayNewList(
							modal,
							newListCheckbox,
							newListInputField,
							newListName,
							data,
						);
						sortLists(modal);
						flashMessages.push(
							successToast(
								"Successfully added to newly created list '" +
									newListName +
									"'..",
							),
						);
					}
					updateAddToFavoritesButton(modal);
					displayToasts(flashMessages);
					closeModal(modalSelector);
					updateSaveButton(saveButton);
				},
				() => {
					flashMessages.push(
						errorToast(
							"Failed to add airline to favourite list(s).",
						),
					);
					displayToasts(flashMessages);
					updateSaveButton(saveButton);
				},
			);
		}

		if (listsToDeleteFrom.length > 0) {
			const data = JSON.stringify({
				codes: [entityCode],
				lists: listsToDeleteFrom,
			});

			api(endpoint).delete(
				data,
				() => {
					updateDeletedListsState(modal, listsToDeleteFrom);
					updateAddToFavoritesButton(modal);
					displayToasts([
						successToast(
							"Successfully deleted from " +
								listsToDeleteFrom.length +
								" list(s).",
						),
					]);
					closeModal(modalSelector);
					updateSaveButton(saveButton);
				},
				() => {
					displayToasts([
						errorToast(
							"Failed to delete airline from favourite list(s).",
						),
					]);
					updateSaveButton(saveButton);
				},
			);
		}
	});

	const closeButtons = modal.querySelectorAll('[data-dismiss="modal"]');

	closeButtons.forEach((close) => {
		close.addEventListener("click", function () {
			uncheckFavorites(modal, newListCheckbox, newListInputField);
		});
	});

	document.addEventListener("keydown", (event) => {
		if (event.key === "Escape") {
			uncheckFavorites(modal, newListCheckbox, newListInputField);
		}
	});
}
