export function startTimer(duration, displayId) {
    let timer = duration, minutes, seconds;
    let display = document.querySelector('#' + displayId);
    let interval = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = duration;
            const form = document.getElementById('sessionConfirmationForm');
            const button = document.createElement('button');
            button.setAttribute('type', 'submit');
            button.setAttribute('id', 'resend-email');
            button.classList.add('btn', 'btn--secondary');
            button.innerText = 'Resend email';
            form.appendChild(button);

            clearInterval(interval);
        }
    }, 1000);
}