import {api} from "../../common/favorites/api";
import {closeModal} from "../../common/modals";
import {displayToasts, errorToast, successToast} from "../../common/toasts/displayToasts";
import {initDeleteModal, redirectToAllListsPage, removeListFromDisplay, showEmptyPlaceholder} from "./utils";

export function deleteList(endpoint, modalSelectorDelete, isIndividualPage) {
    const modalDelete = document.getElementById(modalSelectorDelete);
    const deleteModalButtons = document.querySelectorAll('.btnDelete');
    const buttonDeleteList = modalDelete.querySelector('#btnDelete');

    deleteModalButtons.forEach(button => initDeleteModal(button, modalSelectorDelete));

    buttonDeleteList.addEventListener('click', function () {
        const id = modalDelete.dataset.id;

        const data = JSON.stringify({
            list: id,
        });

        buttonDeleteList.textContent = 'Deleting...';

        api(endpoint).delete(data,
            () => {
                displayToasts([successToast('The list has been deleted.')]);
                isIndividualPage ? redirectToAllListsPage() : removeListFromDisplay(id);
                closeModal(modalSelectorDelete);
                buttonDeleteList.textContent = 'Yes, delete';

                if (document.querySelectorAll('.favorite-list').length === 0) {
                    showEmptyPlaceholder();
                }
            },
            () => {
                displayToasts([errorToast('Failed to delete the list.')]);
                buttonDeleteList.textContent = 'Yes, delete';
            }
        );
    });
}