export function accordion() {
    const accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function (accordion) {
        const accordionButton = accordion.querySelector('.accordion-toggle');

        accordionButton.addEventListener('click', function (e) {
            e.preventDefault();
            const expanded = accordion.getAttribute('aria-expanded');

            if (expanded === "true") {
                accordion.setAttribute("aria-expanded", "false");
            } else {
                accordion.setAttribute("aria-expanded", "true");
            }
        });
    });
}