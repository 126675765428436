import {renderAllTabsOnPrint} from "../common/print/renderAllTabsOnPrint";
import {initRestrictedPopovers} from "../common/restrictedPopovers";
import {initSidebar} from "./sidebar";
import {toggleRows} from "./toggleRows";

export function initProfile(defaultTabId) {
    initSidebar(defaultTabId);
    renderAllTabsOnPrint();
    initRestrictedPopovers();
    toggleRows();
    window.initFeedbackPopup('.feedback-link');
    window.initModals();

    window.addEventListener('hashchange', function (e) {
        e.preventDefault();
        window.initFeedbackPopup('.feedback-link');
        window.initModals();
    })
}