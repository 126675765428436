import "../../scss/styles.scss";
import {
	displayNewslettersSummarySettings,
	displaySummarySettings,
	switchActive,
} from "./account-management/alerts";
import { initDeleteFavoriteListItem } from "./account-management/favorites/deleteItem";
import { initManageFavoriteLists } from "./account-management/favorites/manageFavoritesLists";
import { editRegion } from "./account-management/regions/editRegion";
import { initCapacitiesSelection } from "./capacities";
import { accordion } from "./common/accordion";
import { adsViews } from "./common/adsViews";
import { initDropdowns } from "./common/dropdowns";
import { initFavorites } from "./common/favorites/favorites";
import { initModals } from "./common/modals";
import { initToggles } from "./common/toggle";
import { addOverlay } from "./common/utils";
import { displayForecastingDisclaimer } from "./displayForecastingDisclaimer";
import { initFeedbackPopup } from "./feedback-popup";
import { initFinancialsData } from "./financials";
import { formErrorClear } from "./formErrorClear";
import { initUI } from "./general";
import { initSwiper } from "./initSwiper";
import { displayLeadBoosterChat } from "./leadboosterChat";
import { addTabButton } from "./profile/addTabButton";
import { initProfile } from "./profile/profile";
import { startTimer } from "./resendEmailTimer";
import { initSentryConfig } from "./sentry";
import { sessions } from "./sessions";
import { setupSearchInDropdown } from "./setupSearchInDropdown";
import { initSinglePage } from "./singleProfilePage";
import { subscribe } from "./subscribe";
import { updatePrices } from "./subscription";
import { initDropdownKeyTracking } from "./trackDropdownKey";

// Global
window.initUI = initUI;
window.initModals = initModals;
window.initDropdowns = initDropdowns;
window.initToggles = initToggles;
window.initSinglePage = initSinglePage;
window.initFeedbackPopup = initFeedbackPopup;
window.initSwiper = initSwiper;
window.initFavorites = initFavorites;
window.initSentryConfig = initSentryConfig;
window.updatePrices = updatePrices;
window.editRegion = editRegion;
window.sessions = sessions;
window.subscribe = subscribe;
window.formErrorClear = formErrorClear;
window.initDropdownKeyTracking = initDropdownKeyTracking;
window.setupSearchInDropdown = setupSearchInDropdown;
window.displayLeadBoosterChat = displayLeadBoosterChat;
window.startTimer = startTimer;
window.accordion = accordion;
window.addOverlay = addOverlay;

// Profile pages
window.initProfile = initProfile;
window.addTabButton = addTabButton;
window.initCapacitiesSelection = initCapacitiesSelection;
window.initFinancialsData = initFinancialsData;
window.displayForecastingDisclaimer = displayForecastingDisclaimer;

// Account management
window.switchActive = switchActive;
window.displaySummarySettings = displaySummarySettings;
window.displayNewslettersSummarySettings = displayNewslettersSummarySettings;
window.initManageFavoriteLists = initManageFavoriteLists;
window.initDeleteFavoriteListItem = initDeleteFavoriteListItem;

// Ads
window.adsViews = adsViews;
