import { addOverlay, removeOverlay } from "./utils";

export function initToggles(onToggleClick) {
	const toggles = document.querySelectorAll("[data-toggle]");

	toggles.forEach((toggle) => {
		const elementToToggle = document.getElementById(toggle.dataset.toggle);

		toggle.addEventListener("click", (e) => {
			e.preventDefault();
			toggle.attributes["aria-expanded"].value === "true"
				? hide(toggle, elementToToggle)
				: show(toggle, elementToToggle);
			onToggleClick && onToggleClick();
			toggle.blur();
		});

		document.addEventListener("click", (e) => {
			const opened = toggle.attributes["aria-expanded"].value === "true";
			if (opened && e.target !== toggle && e.target !== elementToToggle) {
				hide(toggle, elementToToggle);
				onToggleClick && onToggleClick();
			}
		});
	});

	function hide(toggle, elementToToggle) {
		toggle.setAttribute("aria-expanded", "false");
		elementToToggle.classList.remove("show");
		removeOverlay();
	}

	function show(toggle, elementToToggle) {
		toggle.setAttribute("aria-expanded", "true");
		elementToToggle.classList.add("show");
		addOverlay();
	}
}
