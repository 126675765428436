import jQuery from "jquery";
import $ from "jquery";
import {waitForElement} from "./waitForElement";

export function ads() {
    const newsSearchPageId = 'news-search-module';
    const isNewsSearch = document.getElementById(newsSearchPageId);

    if (isNewsSearch) {
        waitForElement(`#${newsSearchPageId} .news-list`).then(() => {
            initAdLinks();
        });
    } else {
        initAds();
        initAdLinks();
    }

    function initAdLinks() {
        const ads = document.querySelectorAll('.ad');

        ads.forEach(ad => {
            const observer = new MutationObserver(mutations => {
                mutations.forEach(() => {
                    // Wait to fetch ad content from here src/main/js/news/sidebar/ads/Ad.jsx:14
                    if (ad.querySelector('a')) {
                        initAdLink(ad);
                    }
                });
            });

            initAdLink(ad);
            observer.observe(ad, {childList: true, subtree: true});
        })

        function initAdLink(ad) {
            // Targets third-party ads only
            const link = ad.querySelector('div > a');

            link?.addEventListener('click', (e) => {
                e.preventDefault();
                window.open(link.href, '_blank');
            })
        }
    }

    function initAds() {
        const ads = document.querySelectorAll('[id^="ad_wrapper_"]');
        const mobileAdPrefix = 'article_big_';
        const desktopAdPrefix = 'sidebar_big_';
        let isMobile = window.innerWidth < 576;

        renderAds();

        window.addEventListener("resize", () => {
            const isMobileAfterResize = window.innerWidth < 576;

            if (isMobile !== isMobileAfterResize) {
                isMobile = isMobileAfterResize;
                renderAds();
            }
        });

        function renderAds() {
            ads.forEach(ad => {
                const code = ad.getAttribute('id').replace("ad_wrapper_", "");
                const isMobileOnlyAd = code.includes(mobileAdPrefix);
                const isDesktopOnlyAd = code.includes(desktopAdPrefix);

                if (isMobileOnlyAd) {
                    isMobile && fetchAd(code);
                    return;
                }

                if (isDesktopOnlyAd) {
                    !isMobile && fetchAd(code);
                    return;
                }

                fetchAd(code);
            })
        }

        function fetchAd(code) {
            $.get(`/api/v1/slots/${code}`)
                .done(data => {
                    const content =  data?.html?.replace(/\r?\n|\r/g, "").replace(/"/g, '').trim();
                    const $contentContainer = jQuery(`#ad_wrapper_${code}`);
                    $contentContainer.html(content);
                });
        }
    }
}