import $ from "jquery";
import {
	addCountryTag,
	onRemoveCountryButtonClick,
	removeCountryTag,
} from "./countryTags";

export function editRegion(id, url) {
	let modal = null !== id ? "#edit-" + id : "#create-new";
	let form = null !== id ? "#edit-region-form-" + id : "#create-region-form";
	let nameComponent = null !== id ? "#edit-region-" + id : "#create-region";
	let countriesComponent =
		null !== id ? "#edit-countries-" + id : "#create-countries";
	let submitButton = $(modal).find('[type="submit"]');

	checkSelectedCountry(modal);
	searchCountries();
	selectAllCountries(modal);
	resetForm(modal);

	$(form).submit(function (e) {
		e.preventDefault();
		$(submitButton).html("Saving...");

		let countries = [];

		$(modal + " " + countriesComponent + " .checkbox-input input").each(
			function () {
				let country = $(this);
				if (this.checked) {
					countries.push(country.val());
				}
			},
		);

		let data = {
			regionName: $(nameComponent + " input").val(),
			regionCountries: countries,
		};

		$.post(url, data)
			.done(function (content) {
				if (content.success) {
					window.location =
						window.location.origin + "/account-management/regions";
				} else {
					$(nameComponent).html(content);
					$(submitButton).html("Save List");
				}
			})
			.fail(function () {});
	});
}

function checkSelectedCountry(modal) {
	$(modal + " .checkbox-list.by-continent").each(function () {
		$(this)
			.find("input")
			.each(function () {
				$(this).change(function () {
					let checkbox = $(this);
					let id = checkbox.attr("id");
					if (checkbox.is(":checked")) {
						$("#" + id + "-all").prop("checked", true); // Check checkbox in "All" tab
						addCountryTag(
							modal,
							this.dataset.countryId,
							this.dataset.countryName,
							this.dataset.continentSlug,
						);
					} else {
						$("#" + id + "-all").prop("checked", false); // Uncheck checkbox in "All" tab
						removeCountryTag(modal, this.dataset.countryId);
					}
				});
			});
	});

	$(modal + " .checkbox-list.all-countries")
		.find("input")
		.each(function () {
			$(this).change(function () {
				let checkbox = $(this);
				let id = checkbox.attr("id");

				if (checkbox.is(":checked")) {
					$("#" + id.substring(0, id.lastIndexOf("-"))).prop(
						"checked",
						true,
					); // Check checkboxes in continent tab
					$('input[id^="' + id + '"]').prop("checked", true); // Check "Select all" in continent tab
					addCountryTag(
						modal,
						this.value,
						this.dataset.countryName,
						this.dataset.continentSlug,
					);
				} else {
					$("#" + id.substring(0, id.lastIndexOf("-"))).prop(
						"checked",
						false,
					); // Uncheck checkboxes in continent tab
					$('input[id^="' + id + '"]').prop("checked", false); // Uncheck "Select all" in continent tab
					removeCountryTag(modal, this.value);
				}
			});
		});

	$(modal + " li.tag-country button").on("click", onRemoveCountryButtonClick);
}

function searchCountries() {
	$(".search-countries").each(function () {
		$(this).keyup(function () {
			let value = $(this).val();
			$(this)
				.closest("div")
				.next()
				.find(".checkbox-input")
				.each(function () {
					if (
						$(this)
							.find("label")
							.text()
							.toLowerCase()
							.indexOf(value.toLowerCase()) >= 0
					) {
						$(this).show();
					} else {
						$(this).hide();
					}
				});
		});
	});
}

function selectAllCountries(modal) {
	let checkboxLists = $(modal + " .checkbox-list");
	let allCountriesList = $(modal + " .checkbox-list.all-countries");
	let allCountriesSelectAll = allCountriesList
		.find('input[type="checkbox"]')
		.first();

	checkboxLists.each(function () {
		let checkboxes = $(this).find('input[type="checkbox"]');
		let checkboxesCountries = $(this).find('input[type="checkbox"]:gt(0)');
		let selectAll = checkboxes.first();

		selectAll.click(function () {
			if (this.checked) {
				checkboxes.each(function (index) {
					// Skip "Select all" checkbox
					if (index > 0) {
						$(this).prop("checked", true).change();
					}
				});
			} else {
				checkboxes.each(function (index) {
					// Skip "Select all" checkbox
					if (index > 0) {
						$(this).prop("checked", false).change();
					}
				});
				allCountriesSelectAll.prop("checked", false);
			}
		});

		if (checkboxesCountries.not(":checked").length === 0) {
			selectAll.prop("checked", true);
		}
	});
}

function resetForm(modal) {
	const closeButtons = $(modal).find('[data-dismiss="modal"]');
	const countries = $(modal + " .checkbox-list.all-countries").find("input");
	const defaultTab = $(modal + " .tabs-nav .tabs-list li.is-active");
	const regionName = $(modal).find("#regionName");
	const initialInput = regionName.val();
	let checked = [];

	countries.each(function () {
		if ($(this).is(":checked")) {
			checked.push($(this).val());
		}
	});

	const resetCheckboxes = () => {
		countries.each(function () {
			if (checked.includes($(this).val())) {
				$(this).prop("checked", true).change();
			} else {
				$(this).prop("checked", false).change();
			}
		});
	};

	$(closeButtons).each(function () {
		$(this).click(function () {
			resetCheckboxes();
			defaultTab.trigger("click");
			regionName.val(initialInput);
		});
	});

	document.addEventListener("keydown", (event) => {
		if (event.key === "Escape") {
			resetCheckboxes();
			defaultTab.trigger("click");
			regionName.val(initialInput);
		}
	});
}
