export function sessions() {
    const sessions = document.getElementById('sessionsList');
    const checkboxes = sessions.querySelectorAll('input[type="checkbox"]');

    function handleCheckboxClick(e) {
        const checkbox = e.target;
        checkbox.checked ? checkbox.parentElement.classList.add('active') : checkbox.parentElement.classList.remove('active');
    }

    checkboxes.forEach(checkbox => {
        checkbox.checked ? checkbox.parentElement.classList.add('active') : null;
        checkbox.addEventListener('click', handleCheckboxClick);
    });
}