import {api} from "../../common/favorites/api";
import {closeModal} from "../../common/modals";
import {displayToasts, errorToast, successToast} from "../../common/toasts/displayToasts";
import {displayList} from "./utils";

export function createList(endpoint, modalSelectorEdit, modalSelectorDelete, modalSelectorCreate, isListPage) {
    if (!isListPage) {
        const modalCreate = document.getElementById(modalSelectorCreate);
        const buttonCreateModalOpen = document.getElementById('btnCreate');
        const buttonCreate = modalCreate.querySelector('#btnCreate');
        const inputName = modalCreate.querySelector('input[name="name"]');

        buttonCreateModalOpen.addEventListener('click', function () {
            inputName.value = '';
            inputName.focus();
        });

        inputName.addEventListener('input', function () {
            inputName.parentNode.classList.contains('error') ? inputName.parentNode.classList.remove('error') : null;
        });

        buttonCreate.addEventListener('click', function () {
            const name = inputName.value.trim();

            if (name.length === 0) {
                inputName.parentNode.classList.add('error');
                return;
            }

            buttonCreate.textContent = 'Creating...';

            api(endpoint).create(name, [], [],
                data => {
                    displayToasts([successToast('The list has been created.')]);
                    displayList(data[0], modalSelectorEdit, modalSelectorDelete);
                    closeModal(modalSelectorCreate);
                    buttonCreate.textContent = 'Create';
                },
                () => {
                    displayToasts([errorToast('Failed to create list.')]);
                    buttonCreate.textContent = 'Create';
                }
            );
        });
    }
}