import $ from "jquery";
import { sanitizeHTML } from "../search/utils";

export function initFinancialsData(url) {
	const dataContainer = document.getElementById("financials-data");
	dataContainer.innerHTML = '<span class="loader">Loading...</span>';
	dataContainer.classList.add("loading");

	$.get(url)
		.done(function (response) {
			dataContainer.innerHTML = sanitizeHTML(response);
			dataContainer.classList.remove("loading");
		})
		.fail(function () {
			dataContainer.innerText = "Failed to load data.";
			dataContainer.classList.remove("loading");
		});
}
