import $ from 'jquery';
import {displayPrice} from "./subscription";

export function subscribe(vatCHFactor, vatLIFactor, price) {
    $(document).ready(function () {
        let $country = $('#country');
        let $checkboxes = $('.module input[type=checkbox]');
        let modules = {};

        $checkboxes.each(function () {
            let $module = $(this).closest('.module');
            let id = $(this).val();
            let moduleId = $module.data('module-id');
            modules[moduleId] = {
                id: id,
                checked: $(this).prop('checked'),
                disabled: false,
                dependency: $module.data('dependency'),
                price: parseFloat($module.data('price'))
            }
        });

        let updateTotalPrice = function () {
            let country_id = $country.find('input[name=country]:checked').val();
            let vat;
            if (parseInt(country_id) === 43) {
                vat = {
                    applies: true,
                    rate: vatCHFactor
                };
            } else if (parseInt(country_id) === 129) {
                vat = {
                    applies: true,
                    rate: vatLIFactor
                };
            } else {
                vat = {
                    applies: false,
                    rate: 0
                }
            }

            $checkboxes.each(function () {
                let $module = $(this).closest('.module');
                let moduleId = $module.data('module-id');
                let module = modules[moduleId];
                module.checked = $(this).prop('checked');
                module.disabled = false;
            });

            $.each(modules, function (moduleId, module) {
                if (module.checked && module.dependency > 0) {
                    let dependency = modules[module.dependency];
                    dependency.checked = true;
                    dependency.disabled = true;
                }
            });

            $checkboxes.each(function () {
                let moduleId = $(this).closest('.module').data('module-id');
                let module = modules[moduleId];
                $(this).prop('checked', module.checked);
                $(this).prop('disabled', module.disabled);
            });

            let total = parseFloat(price);
            $.each(modules, function (moduleId, module) {
                total += module.checked ? module.price : 0;
            });
            let vatAmount = total * vat.rate;
            $('#price-excluded-vat').text(displayPrice(total));
            $('#vat-amount').text(displayPrice(vatAmount));
            $('#vat-percent').text(displayPrice(vat.rate * 100));
            $('#vat-container').toggle(vat.applies && vatAmount > 0);

            total += vatAmount;
            $('.price-total .value').text(displayPrice(total));
        };
        updateTotalPrice();
        $checkboxes.on('click', updateTotalPrice);
        $country.on('change', updateTotalPrice);

        function placeFormOnSmallScreens() {
            const subscriptionPackage = $('#subscription-package');
            const subscriptionForm = $('#subscription-form');
            const subscriptionFormFooter = $('#form-footer');

            function setFormPosition() {
                let subscriptionPackageHeight = subscriptionPackage.height();
                let subscriptionFormHeight = subscriptionForm.height();

                let subscriptionFormTopMargin = subscriptionPackageHeight + 150;
                subscriptionForm.css('top', subscriptionFormTopMargin);
                subscriptionFormFooter.css('margin-top', subscriptionFormHeight + 100);
            }

            function resetFormPosition() {
                subscriptionForm.css('top', '');
                subscriptionFormFooter.css('margin-top', '');
            }

            if ($(window).width() < 768) {
                setFormPosition();
            }

            $(window).resize(function () {
                if ($(window).width() < 768) {
                    setFormPosition();
                } else {
                    resetFormPosition();
                }
            });
        }

        placeFormOnSmallScreens();
    });
}