import {onContentLoad} from "./common/onContentLoad";

export function initFeedbackPopup(selector) {
    onContentLoad(function () {
        const links = document.querySelectorAll(selector);

        links.forEach(link => {
            link.addEventListener('click', e => {
                e.preventDefault();
                e.currentTarget.blur();
                const href = link.getAttribute('href');
                window.open(href, 'feedback', 'width=493, height=680, scrollbars=yes, toolbar=no, status=no, resizable=yes, menubar=no, location=no, directories=no, top=10, left=10');
            });
        });
    })
}