import Swiper, {Pagination} from "swiper";

export function initSwiper(container, options = {}) {
    const defaultOptions = {
        slidesPerView: 1,
        speed: 400,
        spaceBetween: 10,
        modules: [Pagination],
        breakpoints: {
            576: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 35
            }
        },
    };

    return new Swiper(container,  Object.assign({}, defaultOptions, options));
}