import { sanitizeHTML } from "../../../search/utils";

export function getListIds(checkedLists) {
	let listsChecked = [];
	let listsToAddTo = [];
	let listsPreselected = [];

	checkedLists.forEach((checkbox) => {
		const id = parseInt(checkbox.value);
		listsChecked.push(id);
		checkbox.hasAttribute("data-preselected")
			? listsPreselected.push(id)
			: listsToAddTo.push(id);
	});

	return { listsChecked, listsToAddTo, listsPreselected };
}

export function getPreselectedLists(modal) {
	const lists = modal.querySelectorAll('input[name="favoriteLists"]');
	let listsPreselected = [];

	lists.forEach((checkbox) => {
		if (checkbox.hasAttribute("data-preselected")) {
			const id = parseInt(checkbox.value);
			listsPreselected.push(id);
		}
	});

	return listsPreselected;
}

export function getListsToDeleteFrom(listsChecked, listsPreselected) {
	let listsToDeleteFrom = [];

	if (listsPreselected.length > 0) {
		listsToDeleteFrom = listsPreselected.filter(
			(obj) => listsChecked.indexOf(obj) === -1,
		);
	}

	return listsToDeleteFrom;
}

export function getNewListName(newListCheckbox, newListInputField) {
	const newName = newListInputField.value.trim();

	return newListCheckbox.checked && newName.length > 0 ? newName : null;
}

export function updateAddedListsState(modal, listsToAddTo) {
	listsToAddTo.forEach(function (id) {
		let label = modal.querySelector("label[for='favoriteLists" + id + "']");
		let count = label.querySelector("span");
		let countIncremented = parseInt(count.textContent) + 1;
		count.innerText = countIncremented.toString();

		let checkbox = modal.querySelector("input[value='" + id + "']");
		checkbox.setAttribute("data-preselected", "");
	});
}

export function updateDeletedListsState(modal, listsToDeleteFrom) {
	listsToDeleteFrom.forEach((id) => {
		let label = modal.querySelector("label[for='favoriteLists" + id + "']");
		let count = label.querySelector("span");
		let countChanged = parseInt(count.textContent) - 1;
		count.innerText = countChanged.toString();

		let checkbox = modal.querySelector("input[value='" + id + "']");
		checkbox.removeAttribute("data-preselected");
	});
}

export function updateSaveButton(saveButton, content = "Save") {
	saveButton.textContent = content;
}

export function displayNewList(
	modal,
	newListCheckbox,
	newListInputField,
	newListName,
	data,
) {
	const favoriteLists = modal.querySelector("#favoriteLists");
	const newListId = data.find((item) => item.name === newListName).id;
	let newList =
		'<div class="checkbox-input">' +
		'<input type="checkbox" id="favoriteLists' +
		newListId +
		'" value="' +
		newListId +
		'" name="favoriteLists" checked data-preselected>' +
		'<label for="favoriteLists' +
		newListId +
		'">' +
		newListName +
		" (<span>1</span>)</label>" +
		"</div>";

	favoriteLists.insertAdjacentHTML("beforeend", sanitizeHTML(newList));
	newListInputField.value = "";
	newListCheckbox.checked = false;
}

export function sortLists(modal) {
	const favoriteLists = modal.querySelector("#favoriteLists");
	const checkboxes = favoriteLists.querySelectorAll(".checkbox-input");

	const ordered = Array.from(checkboxes).sort((item1, item2) =>
		item1.querySelector("label").innerText >
		item2.querySelector("label").innerText
			? 1
			: -1,
	);

	let frag = ordered.reduce(function (frag, checkbox) {
		frag.appendChild(checkbox);
		return frag;
	}, document.createDocumentFragment());

	favoriteLists.appendChild(frag);
}

export function updateAddToFavoritesButton(modal) {
	const addToFavorites = document.querySelector(
		'[data-modal="modalAddToFavorites"]',
	);
	const checkboxes = modal.querySelectorAll("input[data-preselected]");

	if (checkboxes.length > 0) {
		!addToFavorites.classList.contains("active")
			? addToFavorites.classList.add("active")
			: null;
	} else {
		addToFavorites.classList.remove("active");
	}
}

export function uncheckFavorites(modal, newListCheckbox, newListInputField) {
	const lists = modal.querySelectorAll('input[name="favoriteLists"]');

	lists.forEach(function (checkbox) {
		checkbox.checked = checkbox.hasAttribute("data-preselected");
	});
	newListCheckbox.checked = false;
	newListInputField.value = "";
}
