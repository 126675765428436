export function addTabButton(url, label) {
    const tabButtonsContainer = document.getElementById('actionsMenu');

    // Create a custom node for dynamically added buttons
    const tabButtonsNode = document.createElement('div');
    tabButtonsNode.setAttribute('id', 'tabButtons');
    tabButtonsNode.classList.add('tab-buttons');

    // Create an element
    const link = document.createElement('a');
    link.classList.add('btn', 'btn--primary', 'btn--has-icon__mobile', 'btn--news');
    link.setAttribute('href', url);
    const content = document.createElement('span');
    content.innerText = label;

    link.appendChild(content);
    tabButtonsNode.appendChild(link);
    tabButtonsContainer.appendChild(tabButtonsNode);
}