import { closeBurgerMenu } from "../header/toggleMenu";

export function showCookiesSettings(header) {
	const cookiesSettingsButtons = document.querySelectorAll(
		'[data-show="cookiesSettings"]',
	);

	cookiesSettingsButtons.forEach((button) => {
		button.addEventListener("click", () => {
			const cookiesSettings = document.getElementById(
				"main-privacy-banner",
			);

			if (cookiesSettings) {
				cookiesSettings.style.display = "block";
				cookiesSettings.style.height = "70vh";
			}

			closeBurgerMenu(
				header.querySelector('[data-submenu="headerBurgerMenu"]')
					.parentElement,
			);
		});
	});
}
