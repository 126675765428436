import {profilePageHeader} from "./header/profilePage";
import {scroll} from "./header/scroll";
import {showSubmenu} from "./header/showSubmenu";
import {toggleMenu} from "./header/toggleMenu";

export function header() {
    const header = document.getElementById("header");
    const breakpoint = 768;

    if (header) {
        showSubmenu(header);
        scroll(header);
        toggleMenu(header, breakpoint);
        profilePageHeader(header, breakpoint);

        if (document.getElementById('breakingNews')) {
            header.classList.add('breaking-news-enabled');
        }
    }
}