import jQuery from "jquery";
import { sanitizeString } from "../../search/utils";
import { closeModal } from "../common/modals";
import { highlightActiveTab } from "../common/tabs";

export function initSidebar(defaultTabId) {
	let tabHashJustUpdated = false;
	const sidebarNav = document.getElementById("tabs");
	const sidebarLinks = sidebarNav.querySelectorAll("li");
	const sidebarLinkTitle = document.getElementById("tab-title");

	const contentContainer = document.getElementById("tabs-content");
	const $contentContainer = jQuery("#tabs-content");
	const mobileToggle = document.querySelector(
		`[data-toggle="profileSidebar"]`,
	);

	onHashChange();

	window.addEventListener("hashchange", function (e) {
		e.preventDefault();
		onHashChange();
	});

	function onHashChange() {
		// Avoids reloading tab if hash is updated with subTabId
		if (!tabHashJustUpdated) {
			clearCustomButtons();

			const tab = getActiveTab(activeTabId());
			sidebarLinkTitle.innerText = "";
			sidebarLinks.forEach((tab) => tab.classList.remove("active"));
			tab.parentElement.classList.add("active");

			const url = tab.dataset.url;
			const title = tab.textContent;
			loadContent(url, title);

			mobileToggle.innerText = title;
		}
		tabHashJustUpdated = false;
	}

	function loadContent(contentUrl, title) {
		contentContainer.classList.add("loading");
		contentContainer.innerHTML = '<span class="loader">Loading...</span>';

		const setErrorMessage = () => {
			contentContainer.innerHTML = "<p>Failed to load tab.</p>";
			contentContainer.classList.remove("loading");
		};

		fetch(contentUrl, {
			headers: {
				credentials: "same-origin",
				"X-Requested-With": "XMLHttpRequest",
				"Content-Type": "application/json",
			},
		})
			.then((response) => {
				if (response.status === 200) {
					return response.text();
				}

				setErrorMessage();
				return Promise.reject(response);
			})
			.then((data) => {
				contentContainer.classList.remove("loading");

				// Keep jQuery here for now because data inserted contains <script> tags
				// that are not executed with innerHTML
				$contentContainer.html(data);

				sidebarLinkTitle.innerText = title;
				initTabs();
			})
			.catch(() => {
				setErrorMessage();
			});
	}

	// Sub tabs (CHA-6349 need to keep sub tab id in url)
	function initTabs() {
		tabsLinks().forEach((subTabLink) => {
			const parts = subTabLink.split("=");
			if (parts.length === 2) {
				const link = contentContainer.querySelector(
					`#${parts[0]} nav.tabs-nav a[href="#${parts[1]}"]`,
				);
				if (link) {
					highlightActiveTab(link.parentNode);
				}
			}
		});

		const tabsGroups = contentContainer.querySelectorAll("nav.tabs-nav");

		tabsGroups.forEach((tabGroup) => {
			const tabs = tabGroup.querySelectorAll("li a");
			const tabGroupId = tabGroup.parentElement.getAttribute("id");
			const activeTab = tabGroup.querySelector(
				"nav.tabs-nav li.is-active a",
			);
			updateMobileTabMenuButton(activeTab, tabGroupId);

			tabs.forEach((tab) => {
				tab.addEventListener("click", (e) => {
					e.preventDefault();
					updateHashWithTabId(tab);
					closeModal(tabGroupId);
					updateMobileTabMenuButton(tab, tabGroupId);
				});
			});
		});
	}

	function updateHashWithTabId(tabNavButton) {
		tabHashJustUpdated = true;
		const parts = hashParts();
		const tabGroupId = sanitizeString(
			tabNavButton.closest("div.tabs").getAttribute("id"),
		);
		const selectedTabId = sanitizeString(
			tabNavButton.getAttribute("href").substring(1),
		);
		const tabGroupWithTabId = tabGroupId + "=" + selectedTabId;
		let existsInHash = false;

		if (parts.length === 2) {
			const tabLinks = tabsLinks();

			for (let i = 0; i < tabLinks.length; i++) {
				const params = tabLinks[i].split("=");

				if (params.length === 2 && params[0] === tabGroupId) {
					tabLinks[i] = tabGroupWithTabId;
					existsInHash = true;
					location.hash = parts[0] + "?" + tabLinks.join("&");
					break;
				}
			}
		}

		if (!existsInHash) {
			location.hash =
				location.hash +
				(parts.length < 2 ? "?" : "&") +
				tabGroupId +
				"=" +
				selectedTabId;
		}
	}

	function hashParts() {
		const hash = location.hash;
		return hash ? hash.substring(1).split("?") : [];
	}

	function tabsLinks() {
		const parts = hashParts();
		if (parts.length === 2) {
			return parts[1].split("&");
		}
		return [];
	}

	function activeTabId() {
		return location.hash ? hashParts()[0] : defaultTabId;
	}

	function getActiveTab(tabId) {
		const tab = sidebarNav.querySelector(`a[href="#${tabId}"]`);

		if (tab) {
			return tab;
		}

		return sidebarNav.querySelector(`a[href="#${defaultTabId}"]`);
	}

	function clearCustomButtons() {
		const tabButtons = document.getElementById("tabButtons");

		if (tabButtons) {
			tabButtons.remove();
		}
	}

	const updateMobileTabMenuButton = (tab, tabGroupId) => {
		const mobileTabMenuButton = contentContainer.querySelector(
			`[data-modal="${tabGroupId}"]`,
		);

		if (mobileTabMenuButton) {
			mobileTabMenuButton.innerText = tab.innerText;
		}
	};
}
