export function searchResults() {
    const isSearchPage = document.querySelector('.page--search');

    if (isSearchPage) {
        // Toggle <span> next to buttons in sortable cells because Webkit/Chrome
        // will not show <button> in repeatable headers in print view
        const sortButtonSelector = '.btn--sort';

        window.onbeforeprint = () => {
            const tableHeaderSortButtons = document.querySelectorAll(sortButtonSelector);

            if (tableHeaderSortButtons.length > 0) {
                tableHeaderSortButtons.forEach(button => {
                    const textNode = document.createElement("span");
                    textNode.innerText = button.innerText;
                    button.parentElement.appendChild(textNode);
                })
            }
        };

        window.onafterprint = () => {
            const tableHeaderSortButtons = document.querySelectorAll(sortButtonSelector);

            if (tableHeaderSortButtons.length > 0) {
                tableHeaderSortButtons.forEach(button => {
                    button.parentElement.querySelector('span').remove();
                })
            }
        };
    }
}