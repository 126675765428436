const dropdownToggleSelector = '.dropdown-toggle:not([data-toggle])';
const dropdownMenuSelector = '.dropdown-menu';

export function initDropdowns(selector, onDropdownItemClick) {
    const dropdowns = document.querySelectorAll(selector || '.dropdown');

    if (dropdowns) {
        dropdowns.forEach(dropdown => {
            const dropdownToggle = dropdown.querySelector(dropdownToggleSelector);

            if (dropdownToggle) {
                dropdown.querySelector(dropdownToggleSelector).addEventListener('click', handleDropdownClick);

                if (onDropdownItemClick) {
                    dropdown.querySelectorAll('.dropdown-item').forEach(item => {
                        item.addEventListener('click', onDropdownItemClick);
                    });
                }
            }
        })

        document.addEventListener('click', handleOutsideDropdownClick);
    }

    function handleDropdownClick(e) {
        e.preventDefault();
        const dropdown = this.closest('.dropdown');
        const dropdownMenu = dropdown.querySelector(dropdownMenuSelector);
        const dropdownMenuItems = dropdownMenu.querySelectorAll('.dropdown-item');

        dropdownMenuItems.length > 10 ? dropdown.classList.add('dropdown--scrollable') : null;
        this.attributes['aria-expanded'].value === "true" ? closeDropdown(dropdown) : openDropdown(dropdown);

        dropdownMenuItems.forEach(item => {
            item.addEventListener('click', handleDropdownItemClick)
        });
    }

    function handleDropdownItemClick() {
        const dropdown = this.closest('.dropdown');
        const dropdownToggle = dropdown.querySelector(dropdownToggleSelector);

        const component = this.closest('.form-group');
        const error = component ? component.querySelector('.error-text') : null;

        if (error) {
            error.style.display = 'none';
            component.classList.remove('error');
        }

        const isNullOptionSelected = this.getAttribute('for') === 'null-label';
        dropdown.setAttribute('data-selected', !isNullOptionSelected);
        dropdownToggle.innerText = this.innerText;
        closeDropdown(dropdown);
    }

    function handleOutsideDropdownClick(e) {
        dropdowns.forEach(dropdown => {
            const dropdownToggle = dropdown.querySelector(dropdownToggleSelector);

            if (dropdownToggle && e.target !== dropdownToggle) {
                closeDropdown(dropdown);
            }
        })
    }

    function closeDropdown(dropdown) {
        const dropdownToggle = dropdown.querySelector(dropdownToggleSelector);
        const dropdownMenu = dropdown.querySelector(dropdownMenuSelector);
        dropdownToggle.setAttribute("aria-expanded", "false");
        dropdownMenu?.classList.remove("dropdown-menu--opened");
    }

    function openDropdown(dropdown) {
        const dropdownToggle = dropdown.querySelector(dropdownToggleSelector);
        const dropdownMenu = dropdown.querySelector(dropdownMenuSelector);

        dropdownToggle.setAttribute("aria-expanded", "true");
        dropdownMenu.classList.add("dropdown-menu--opened");
    }
}