export function validateOnClick(modal, newListCheckbox, newListName, newListInputField, listsToAddTo, listsToDeleteFrom) {
    let valid = true;

    if (newListCheckbox.checked === true && newListName === null) {
        newListInputField.parentNode.classList.add('error');
        valid = false;
    }

    if (listsToAddTo.length === 0 && listsToDeleteFrom.length === 0 && newListName === null) {
        const footer = modal.querySelector("footer");
        footer.classList.add('error');
        valid = false;
    }

    return valid;
}

export function validateForm(modal, newListInput, newListCheckbox) {
    const lists = modal.querySelectorAll('input[name="favoriteLists"]');

    newListInput.addEventListener('focus', function () {
        newListCheckbox.checked = true;
    });

    newListInput.addEventListener('input', function () {
        newListInput.parentNode.classList.contains("error") ? newListInput.parentNode.classList.remove("error") : null;
    });

    newListCheckbox.addEventListener('change', function () {
        if (newListCheckbox.checked === false) {
            newListInput.parentNode.classList.contains("error") ? newListInput.parentNode.classList.remove("error") : null;
        }
    });

    lists.forEach((checkbox) => {
        checkbox.addEventListener('change', function () {
            if (checkbox.checked === true) {
                const error = modal.querySelector('footer.error');
                error ? error.classList.remove('error') : null;
            }
        });
    });
}