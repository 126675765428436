import { escapeRegExp } from "lodash";

const optionTracker = {};

export const setupSearchInDropdown = (ids) => {
	ids.forEach((id) => {
		const dropdown = document.getElementById(id);
		dropdown.onclick = (e) => {
			e.stopPropagation();
		};
		dropdown.insertAdjacentHTML(
			"afterbegin",
			'<input type="text" placeholder="Type to search" class="appended-dropdown-input">',
		);
		const input = dropdown.children[0];
		input.addEventListener("input", filterOptions);
		optionTracker[id] = Array.from(dropdown.children[1]?.children || []);
	});
};

const filterOptions = (e) => {
	const options = optionTracker[e.target.parentElement.id];
	const filteredOptions = options.filter((option) =>
		new RegExp(e.target.value, "i").test(escapeRegExp(option.innerText)),
	);
	e.target.nextSibling.replaceChildren(...filteredOptions);
};
