import {initCloseToasts} from "./closeToasts";
import {initToasts} from "./initToasts";

export function toasts() {
    const toasts = document.getElementById('toasts');

    if (toasts) {
        initToasts(toasts);
        initCloseToasts(toasts.querySelectorAll('.toast'));
    }
}