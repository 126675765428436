import jQuery from "jquery";

export function api(url) {
    return {
        create: function (name, codes, lists, onSuccess, onError) {
            const data = JSON.stringify({
                name: name || '',
                codes,
                lists,
            });

            jQuery.ajax({
                type: 'POST',
                url,
                data,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }).done(data => onSuccess(data)).fail(onError);
        },

        save: function (id, name, onSuccess, onError) {
            const data = JSON.stringify({
                list: id,
                name,
            });

            jQuery.ajax({
                type: 'PUT',
                url,
                data,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
            }).done(onSuccess).fail(onError);
        },

        delete: function (data, onSuccess, onError) {
            jQuery.ajax({
                type: 'DELETE',
                url,
                data,
                contentType: "application/json; charset=utf-8",
                dataType: 'json'
            }).done(onSuccess).fail(onError);
        }
    };
}